.app {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: #1a2533;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .body {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
}
